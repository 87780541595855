import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Table, Popconfirm } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { dateFromObjectId } from '../../utility/utility';

const RechargesTable = ({ pageNumber, onChangePageNumber, onDeleteRecharge }) => {
  const { filterRecharges, recharges, total } = useSelector(state => {
    return {
      filterRecharges: state.recharges.filterRecharges,
      recharges: state.recharges.recharges,
      total: state.recharges.totalResults,
    };
  });

  const [state, setState] = useState({
    notData: filterRecharges,
    item: recharges,
    selectedRowKeys: [],
  });

  const { selectedRowKeys } = state;

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  useEffect(() => {
    if (recharges) {
      setState({
        item: recharges,
        selectedRowKeys,
      });
    }
  }, [recharges, selectedRowKeys]);

  const handleOnDeleteRecharge = rechargeId => {
    onDeleteRecharge(rechargeId);
  };

  const dataSource = [];
  if (recharges.length) {
    let iteareProp = recharges;
    if (filterRecharges?.length > 0) iteareProp = filterRecharges;
    iteareProp.map((value, key) => {
      const {
        statusRecharge,
        id,
        rechargeAmount,
        subtotal,
        total: formTotal,
        statusPay,
        createdBy,
        paymentType,
        statusCorte,
        cardId,
        memberCardAmount,
      } = value;

      return dataSource.push({
        key: key + 1,
        id: <span className="recharge-id">{id.substring(8)}</span>,
        createdBy: <span>{createdBy?.name}</span>,
        cardName: <span>{cardId?.name}</span>,
        cardNumber: <span>{cardId?.cardname}</span>,
        statusCorte: (
          <span
            className={`status ${
              statusCorte === 'Finalizado' ? 'Success' : statusCorte === 'Pendiente' ? 'warning' : 'error'
            }`}
          >
            {statusCorte}
          </span>
        ),
        paymentType: (
          <span
            className={`status ${
              paymentType === 'Efectivo' ? 'info' : paymentType === 'Tarjeta' ? 'Success' : 'error'
            }`}
          >
            {paymentType || 'Por Pagar'}
          </span>
        ),

        rechargeAmount: <span className="rechargeed-amount">${rechargeAmount}</span>,
        subtotal: <span className="rechargeed-amount">${subtotal}</span>,
        memberCardAmount: <span className="rechargeed-amount">${memberCardAmount}</span>,
        total: <span className="rechargeed-amount">${formTotal}</span>,
        date: <span className="rechargeed-date">{dateFromObjectId(id)}</span>,
        action: (
          <div className="table-actions">
            <>
              <Popconfirm
                title="¿Seguro que quieres borrar esta recarga? Se reducira el saldo de la tarjeta"
                onConfirm={() => {
                  if (statusRecharge === 'Finalizada' && statusPay === 'Pagada') {
                    const confirm = window.confirm('Estás eliminando una orden finalizada. ¿Estás seguro?');
                    if (!confirm) return;
                  }
                  handleOnDeleteRecharge(id);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button className="btn-icon" type="danger" shape="circle">
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          </div>
        ),
      });
    });
  }

  const columns = [
    {
      title: 'Id Recarga',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre Tarjeta',
      dataIndex: 'cardNumber',
      key: 'cardNumber',
    },
    {
      title: 'Nombre Tarjeta',
      dataIndex: 'cardName',
      key: 'cardName',
    },
    {
      title: 'Creada Por',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Estatus Corte',
      dataIndex: 'statusCorte',
      key: 'statusCorte',
    },
    {
      title: 'Método de Pago',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Monto Recarga',
      dataIndex: 'rechargeAmount',
      key: 'rechargeAmount',
    },

    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <PageHeader ghost title="Recargas Historial" buttons={[<div key="1" className="page-header-actions" />]} />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-recharge table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    pageSize: 25,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

RechargesTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
  onDeleteRecharge: PropTypes.func,
};
export default RechargesTable;
