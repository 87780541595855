import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Table, Form, Input, Spin, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import QrReader from 'react-qr-reader';
import FontAwesome from 'react-fontawesome';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper, BasicFormWrapper, AddUser, SearchResultWrapper } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { createCard, rechargeCard } from '../../redux/cards/actionCreator';
import SummaryPayment from '../summaryPayment/summaryPayment';
import { AutoComplete } from '../autoComplete/autoComplete';

const CardsTable = ({ pageNumber, onChangePageNumber, onSearchCards, searchText }) => {
  const { filterCards, cards, total, loadingCreate, loadingRecharge } = useSelector(state => {
    return {
      filterCards: state.filterCards,
      cards: state.cards.cards,
      total: state.cards.totalResults,
      loadingRecharge: state.cards.loadingRecharge,
    };
  });
  const login = useSelector(state => state.auth.login);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    notData: filterCards,
    selectedRowKeys: [],
    update: {},
    modalVisible: false,
    showCam: false,
    modalPasswordVisible: false,
    idToUpdate: '',
    result: {},
    name: '',
    email: '',
    cardname: '',
    description: '',
    coinpurse: 0,
    montoRecarga: 0,
    qrData: '',
    err: '',
    modalChargeVisible: false,
    paymentRechargeVisible: false,
    rechargeAmount: 0,
    amountToPay: 0,
    amountPayed: 0,
    idToRecharge: '',
    paymentType: 'Efectivo',
  });

  useEffect(() => {
    if (state.qrData) {
      setState({ ...state, showCam: false });
      // eslint-disable-next-line no-use-before-define
      requestCard();
    }
    // eslint-disable-next-line
  }, [state.qrData]);

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  const handleCancel = () => {
    setState({ ...state, modalVisible: false, modalPasswordVisible: false });
  };

  const handleOk = data => {
    dispatch(createCard(data));
  };

  const toggleModal = () => {
    setState({ ...state, modalVisible: !state.modalVisible });
  };

  const toggleModalCharge = () => {
    setState({ ...state, modalChargeVisible: !state.modalChargeVisible });
  };

  const handleQR = () => {
    setState({ ...state, showCam: !state.showCam });
  };

  const handleScan = data => {
    if (data) {
      form.setFieldsValue({
        cardname: data,
      });
      setState({ ...state, qrData: data, showCam: !state.showCam });
    }
  };

  const handleError = err => {
    setState({ ...state, err });
  };

  const handleOkRecharge = form => {
    const { rechargeAmount } = form;
    setState({
      ...state,
      modalChargeVisible: false,
      paymentRechargeVisible: true,
      rechargeAmount: Number.parseInt(rechargeAmount),
    });
  };

  const handleCancelPayment = () => {
    setState({
      ...state,
      paymentRechargeVisible: false,
      amountToPay: 0,
      idToRecharge: '',
      amountPayed: 0,
      rechargeAmount: 0,
    });
  };

  const handleOnFinishPayment = () => {
    const { amountToPay, amountPayed, idToRecharge, rechargeAmount, paymentType } = state;
    const data = {
      amountToPay,
      amountPayed,
      idToRecharge,
      rechargeAmount,
      paymentType,
    };
    if (rechargeAmount > amountPayed) {
      message.warn('Debe ingresar un cantidad mayor o igual al monto a pagar');
      return;
    }
    dispatch(rechargeCard(data));
  };

  const handleOnChargeCard = idCardName => {
    setState({ ...state, modalChargeVisible: true, idToRecharge: idCardName });
  };

  const requestCard = () => {
    if (cards.length) {
      cards.map(value => {
        const { name, cardname, coinpurse } = value;
        if (state.qrData === cardname) {
          setState({ ...state, name, cardname, coinpurse });
        }
        return '';
      });
    }
  };

  const handleOnSearch = _searchText => {
    onSearchCards(_searchText);
  };

  const dataSource = [];
  if (cards.length) {
    cards.map((value, key) => {
      const { id, name, cellphone, email, cardname, description, coinpurse = 0, montoRecarga = 0 } = value;

      return dataSource.push({
        key: key + 1,
        id: <span className="id">{id.substring(8)}</span>,
        name: <span className="name">{name}</span>,
        cellphone: <span className="cellphone">{cellphone}</span>,
        email: <span className="email">{email}</span>,
        cardname: <span className="cardname">{cardname}</span>,
        description: <span className="description">{description}</span>,
        coinpurse: <span className="coinpurse">${coinpurse + montoRecarga}</span>,
        action: (
          <div className="table-actions">
            <Button onClick={() => handleOnChargeCard(id)}>
              <FontAwesome name="money" style={{ marginRight: '5px', fontSize: '16px' }} />
            </Button>
          </div>
        ),
      });
    });
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telefono',
      dataIndex: 'cellphone',
      key: 'cellphone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nombre Tarjeta',
      dataIndex: 'cardname',
      key: 'cardname',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Monedero',
      dataIndex: 'coinpurse',
      key: 'coinpurse',
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <PageHeader
        ghost
        title="Tarjetas"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button className="btn-add_new" size="default" key="1" type="primary" onClick={toggleModal}>
              <FeatherIcon icon="user-plus" size={14} /> Agregar Tarjeta
            </Button>
          </div>,
        ]}
      />
      ,
      <Main>
        <SearchResultWrapper>
          <Row gutter={25}>
            <Col xs={24}>
              <AutoComplete
                value={searchText}
                onSearch={() => {}}
                onSearchButton={handleOnSearch}
                dataSource={[]}
                placeholder="Buscar Tarjeta"
                width="35%"
                patterns
              />
            </Col>
          </Row>
        </SearchResultWrapper>
        <Modal title="Informacion de la tarjeta" visible={state.modalVisible} footer={null} onCancel={handleCancel}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="card" onFinish={handleOk}>
                  <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingresa su nombre' }]}>
                    <Input placeholder="Ingresar Nombre" />
                  </Form.Item>
                  <Form.Item
                    label="Numero de Telefono"
                    name="cellphone"
                    rules={[{ required: true, message: 'Ingresa numero de telefono' }]}
                  >
                    <Input placeholder="numero de telefono" />
                  </Form.Item>
                  <Form.Item
                    label="Correo Electronico"
                    name="email"
                    rules={[{ required: true, message: 'Ingresa su correo' }]}
                  >
                    <Input placeholder="Correo Electronico" />
                  </Form.Item>

                  <Form.Item
                    label="Numero Tarjeta"
                    name="cardname"
                    rules={[{ required: true, message: 'Ingrese la tarjeta' }]}
                  >
                    <Button className="btn-read_card" size="default" key="2" type="primary" onClick={handleQR}>
                      <FeatherIcon icon="user-plus" size={14} /> QR Leer Tarjeta
                    </Button>
                    {state.showCam ? (
                      <QrReader
                        showViewFinder={state.showCam}
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '300px' }}
                      />
                    ) : (
                      <span />
                    )}
                    <Input
                      style={{ marginTop: '10px' }}
                      value={state.qrData}
                      readOnly
                      max={10}
                      placeholder="Numero de tarjeta"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Descripción"
                    name="description"
                    rules={[{ required: false, message: 'Ingresa una Descripción' }]}
                  >
                    <Input placeholder="Descripción (opcional)" />
                  </Form.Item>

                  <Form.Item
                    label="Monto"
                    name="montoRecarga"
                    initialValue={0}
                    rules={[{ required: true, message: 'Proporciona un monto válido' }]}
                  >
                    <Input
                      type="number"
                      value={login?.user?.role !== 'admin' && 0}
                      disabled={login?.user?.role !== 'admin'}
                      placeholder="Monto Inicial"
                    />
                  </Form.Item>

                  {loadingCreate ? (
                    <Spin />
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Agregar Nueva Tarjeta
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>
        <Modal title="Recarga de Tarjeta" visible={state.modalChargeVisible} footer={null} onCancel={toggleModalCharge}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="rechargeCard" onFinish={handleOkRecharge}>
                  <Form.Item
                    name="rechargeAmount"
                    label="Monto a Recargar"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa una cantidad para recargar',
                      },
                    ]}
                  >
                    <Input type="number" placeholder="monto" />
                  </Form.Item>

                  {loadingCreate ? (
                    <Spin />
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Recargar
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>

        <Modal
          title="Pagar Orden"
          type="info"
          visible={state.paymentRechargeVisible}
          onOk={handleOnFinishPayment}
          confirmLoading={loadingRecharge}
          onCancel={handleCancelPayment}
          okText="Pagar"
          cancelText="Cancelar"
        >
          <SummaryPayment
            clients={[]}
            subtotal={state.rechargeAmount || 0}
            products={1}
            amount={state.amountPayed}
            monedero={0}
            paymentType={state.paymentType}
            onChangeAmount={amountPayed => setState({ ...state, amountPayed })}
            onChangeDiscount={() => {}}
            onChangePaymentType={paymentType => setState({ ...state, paymentType })}
            onChangecardQR={() => {}}
          />
        </Modal>

        <Cards headless>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

CardsTable.propTypes = {
  pageNumber: PropTypes.number,
  searchText: PropTypes.string,
  onChangePageNumber: PropTypes.func,
  onSearchCards: PropTypes.func,
};
export default CardsTable;
